<template>
  <div class="hotLine_warp">
    <div class="hotLine_content">
      <a-spin :spinning="loading">
        <div class="hotLine_top">
          <div class="title">
            <a-icon class="icon" type="schedule" style="font-size: 20px;" />
            <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">软控运维服务平台调查问卷</span>
          </div>
          <div class="close" @click="$router.back(-1)">
            <a-icon class="icon" type="left" />
            返回
          </div>
        </div>

        <a-row type="flex" justify="center" align="middle" style="padding-bottom: 20px;">
          <a-col :span="24" type="flex" justify="center" align="center"
                 style=" font-weight: bolder; align-items: center;">
            <h1>{{ questionnaireInfo.questionnaireName }}</h1>
            <h5 style="color:rgba(0,0,0,0.45);">调查时间：{{ questionnaireInfo.beginTime }}至{{ questionnaireInfo.endTime
              }}</h5>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center" align="middle" style="padding-bottom: 20px;">
          <a-col :span="20">
            <a-form-model ref="userAnswerInfo" :model="userAnswerInfo" :rules="questionRules">
              <a-form-model-item v-for="(question,questionIndex) in questionnaireInfo.soPaperBankVO.questionList"
                                 :key="'question'+questionIndex"
                                 :prop="question.id"
                                 :label="(questionIndex+1)+'、'+question.questionInfo">
                <!--单选题-->
                <a-radio-group v-if="question.questionType === 1" v-model="userAnswerInfo[question.id]"
                               :key="'QUE'+question.id">
                  <a-row>
                    <a-col :span="24" v-for="(option,optionIndex) in question.questionOptionList"
                           :key="'QUE'+question.id+'OPT'+optionIndex+'col'">
                      <a-radio :value="option.id"
                               :name="'QUE'+question.id">
                        {{ option.questionOption }}
                      </a-radio>
                    </a-col>
                  </a-row>

                </a-radio-group>

                <!--多选题-->
                <a-checkbox-group v-if="question.questionType === 2" v-model="userAnswerInfo[question.id]"
                                  :key="'QUE'+question.id">
                  <a-row>
                    <a-col :span="24" v-for="(option,optionIndex) in question.questionOptionList"
                           :key="'QUE'+question.id+'OPT'+optionIndex+'col'">
                      <a-checkbox :value="option.id"
                                  :name="'QUE'+question.id">
                        {{ option.questionOption }}
                      </a-checkbox>
                    </a-col>
                  </a-row>

                </a-checkbox-group>

                <!--问答题-->
                <a-input v-if="question.questionType === 3" v-model="userAnswerInfo[question.id]"
                         :key="'QUE'+question.id" />

                <!--简单题-->
                <a-input v-if="question.questionType === 4" v-model="userAnswerInfo[question.id]" type="textarea"
                         :key="'QUE'+question.id" />

              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center" align="middle" style="padding-bottom: 20px;">
          <a-col :span="24" type="flex" justify="center" align="center"
                 style=" font-weight: bolder; align-items: center;">
            <a-button type="primary" @click="onSubmit" :loading="submitLoading">
              提交
            </a-button>
            <!--            <a-button style="margin-left: 10px;" @click="resetForm" :disabled="submitLoading">-->
            <!--              重置-->
            <!--            </a-button>-->
          </a-col>
        </a-row>
      </a-spin>
    </div>

  </div>
</template>

<script>
import { getAction, httpAction } from '@/api/manage'
import { MESNAC_SYSTEM_OPS_URL } from '@/views/user/modules/config'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'

export default {
  data() {
    return {
      url: MESNAC_SYSTEM_OPS_URL,

      userInfo: Vue.ls.get(USER_INFO),
      visible: false,
      loading: false,
      loadingNum: 2,

      // 调查问卷信息
      questionnaireInfo: {
        questionnaireName: '调查问卷',
        beginTime: '2022-02-22 20:22:22',
        endTime: '2022-02-22 20:22:22',
        soPaperBankVO: {
          questionList: []
        }
      },
      questionRules: {},
      userAnswerInfo: {},
      submitLoading: false
    }
  },
  created() {
    this.loadInfo()
  },
  watch: {
    loadingNum: {
      handler(newValue, oldValue) {
        console.log('loadingNum', newValue)
        if (newValue <= 0) {
          this.loading = false
        } else {
          this.loading = true
        }
      }
    }
  },
  methods: {

    //////////////////////////////////////////////////
    // 载入信息
    loadInfo() {
      console.log('queryInfo:', this.$route.query)
      console.log('userInfo:',this.userInfo)

      // 检测用户是否已答题
      getAction(this.url.checkQuestionnaireUserInstanceExist, {
        questionnaireId: this.$route.query.questionnaireId,
        userName: this.userInfo.username
      }).then(res => {
        if (res.success) {
          console.log('checkQuestionnaireUserInstanceExist success:', res)

          if (!res.result){
            // 不存在用户答题信息时， 获取调查问卷信息
            getAction(this.url.getQuestionnaireInfoById, { questionnaireId: this.$route.query.questionnaireId }).then(res => {
              if (res.success) {
                console.log('getQuestionnaireInfoById:', res)
                this.questionnaireInfo = res.result
                // 生成校验rules
                let questionList = this.questionnaireInfo.soPaperBankVO.questionList
                console.log('questionList:', questionList)

                let questionRulesStr = '{'
                questionList.forEach((question) => {
                  questionRulesStr += '"' + question.id + '":[{ "required": "true", "message": "请回答' + question.questionInfo + '", "trigger": "blur" }],'
                })
                questionRulesStr = questionRulesStr.substring(0, questionRulesStr.length - 1) + '}'
                this.questionRules = JSON.parse(questionRulesStr)
                console.log('questionRules:', this.questionRules)
              } else {
                this.$message.error('获取调查问卷信息失败：' + res.message)
              }
              this.loadingNum--
            })
          }else{
            this.$message.success({ content: '您已提交本次调查问卷，无需重复参与', duration: 2, onClose: () => {
                this.$router.back(-1)
                this.loadingNum--
              } })
          }
        } else {
          this.$message.error('检测用户答题情况失败：' + res.message)
        }
        this.loadingNum--
      })
    },

    // 提交问卷
    onSubmit() {
      this.$refs.userAnswerInfo.validate((err) => {
        if (err) {
          let that = this
          this.$confirm({
            title: '确认',
            content: '确认提交问卷?',
            onOk: function() {
              that.submitLoading = true
              // 生成待提交数据
              let formData = {}
              formData.userName = that.userInfo.username
              formData.userRealName = that.userInfo.realname
              formData.sysOrgCode = that.userInfo.orgCode
              formData.questionnaireId = that.questionnaireInfo.id
              formData.userAnswerInfo = that.userAnswerInfo

              const key = 'submitQuestionnaireUserAnswerInfo'
              that.$message.loading({ content: '提交中......', key })

              httpAction(that.url.submitQuestionnaireUserAnswerInfo, formData, 'post').then(res => {
                if (res.success) {
                  that.$message.success({
                    content: '提交成功!', key, duration: 2, onClose: () => {
                      that.$router.back(-1)
                    }
                  })
                } else {
                  that.submitLoading = false
                  that.$message.error({ content: '提交错误[' + res.message + ']', key, duration: 2 })
                }
              })
            }
          })

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 重置问卷
    resetForm() {
      this.$confirm({
        title: '确认',
        content: '确认重置已答问卷?',
        onOk: () => {
          this.$refs.userAnswerInfo.resetFields()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.height-80 {
  height: 80px;
}

.info_bg {
  // background: url('~@/assets/img/service_bg.png') no-repeat center;
  padding: 40px 20px 40px 20px;
}

.hotLine_warp {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background: #f6f6f6;

  .hotLine_content {
    min-height: 800px;
    width: 1559px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;

    .hotLine_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        font-size: 22px;
        color: rgba(16, 16, 16, 100);
        font-family: SourceHanSansSC-bold;
      }

      .close {
        cursor: pointer;

        .icon {
          font-weight: 600;
          font-size: 22px;
        }
      }
    }
  }
}

///deep/.ant-table-thead{
//  display: none;
//}
</style>

